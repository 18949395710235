import * as React from "react";
import Modal from "@cloudscape-design/components/modal";
import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import Cards from "@cloudscape-design/components/cards";

export default ({ modalVisible, closeModal }) => {
  console.log("modal input", modalVisible);
  return (
    <Modal
      onDismiss={closeModal}
      visible={modalVisible}
      //   footer={
      //     <Box float="right">
      //       {/* <SpaceBetween direction="horizontal" size="xs">
      //         <Button variant="link">Cancel</Button>
      //         <Button variant="primary">Ok</Button>
      //       </SpaceBetween> */}
      //     </Box>
      //   }
      header={
        <Cards
          ariaLabels={{
            itemSelectionLabel: (e, n) => `select ${n.name}`,
            selectionGroupLabel: "Item selection",
          }}
          cardDefinition={{
            // header: (item) => <Link fontSize="heading-m" >{item.name}</Link>,
            header: (item) => (
              <a
                fontSize="heading-m"
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.name}
              </a>
            ),
            sections: [
              {
                id: "description",
                content: (item) => item.description,
              },
            ],
          }}
          cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
          items={[
            {
              name: "AWS IAM Identity Center",
              alt: "First",
              description: "Sign in with AWS IAM Identity Center.",
              link: "https://d-90679d05b9.awsapps.com/start",
            },
            {
              name: "AWS Cognito",
              alt: "Second",
              description: "Sign in with AWS Cognito.",
            },
            {
              name: "Google",
              alt: "Third",
              description: "Sign in with Google.",
            },
            {
              name: "Other Identity Provider",
              alt: "Fourth",
              description: "Sign in with other identity provider.",
            },
          ]}
          loadingText="Loading resources"
          empty={
            <Box textAlign="center" color="inherit">
              <b>No resources</b>
              <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                No resources to display.
              </Box>
              <Button>Create resource</Button>
            </Box>
          }
          //   header={<Header>Sign In</Header>}
        />
      }
    >
      {/* Your description should go here */}
    </Modal>
  );
};
