import { useState } from "react";
import useFetch from "../../useFetch"
import { Link, useLocation } from 'react-router-dom';
import Form from "@cloudscape-design/components/form";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import FormField from "@cloudscape-design/components/form-field";
import urlPrefix from "../../urlPrefixGetter"

const S3Content = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    // Access the query parameters
    let currentDirectoryPath = params.get('directoryPath');
    const bucketName = params.get('bucketName');
    let urlTOFetch = `/ListContents?directoryPath=${currentDirectoryPath}&bucketName=${bucketName}`
    if (currentDirectoryPath == null) {
        urlTOFetch = `/ListContents?&bucketName=${bucketName}`
        currentDirectoryPath = ''
    }
    const {data: s3Content, error, isPending} = useFetch(urlTOFetch)
    const [directoryPathToAdd, setDirectoryPathToAdd] = useState('');
    const [isPendingAdd, setIdPendingAdd] = useState(0);
    const [fileToAdd, setFileToAdd] = useState('');

    const handleSubmitFile = (e) => {
        e.preventDefault();
        let urlToPost = `${urlPrefix()}/MakeFile?bucketName=${bucketName}&directoryPath=${currentDirectoryPath}`
        if (currentDirectoryPath == '') {
            urlToPost = `${urlPrefix()}/MakeFile?bucketName=${bucketName}&directoryPath=\/`
        }
        const direcotryPathInForm = {directoryPath: currentDirectoryPath };
        setIdPendingAdd(1);
        const formData = new FormData();
        formData.append('file', fileToAdd);
        fetch(urlToPost, {
          method: 'POST',
          body: formData
        }).then(() => {
          console.log('new file added', direcotryPathInForm);
          setIdPendingAdd(0);
        })
      }
      
    const handleSubmit = (e) => {
        e.preventDefault();
        const absoultePath = currentDirectoryPath + directoryPathToAdd + '/';
        const direcotryPathInForm = {directoryPath: absoultePath};
        setIdPendingAdd(-1);
        fetch(`${urlPrefix()}/MakeDirectory?directoryPath=${absoultePath}&bucketName=${bucketName}`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(direcotryPathInForm)
        }).then(() => {
        console.log('new directory added', absoultePath, direcotryPathInForm);
        setIdPendingAdd(0);
        })
    }
    return (
        <div>
        { (isPending) && <div> Loading...</div> }
        { error && <div>{ error }</div> }
        <br></br>

        {((!isPending) && (s3Content.every((item) => item.name == ''))) && (!error) && <h2>Empty Folder</h2>}
        <br></br>
        {s3Content&& s3Content.map(contentObj => (
            <div className="links">
            <Link to={`/ListContents?directoryPath=${currentDirectoryPath + contentObj.name + '/'}&bucketName=${bucketName}`}>
            <a className="linktext">{ contentObj.name }</a>
            </Link>
        </div>
        ))}
        <br></br>
        <div>
            <form onSubmit={handleSubmit}>
                <Form
                actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button formAction="none" variant="link">
                        Cancel
                        </Button>
                        {(isPendingAdd >= 0) && <Button variant="primary">Submit</Button>}
                        {(isPendingAdd < 0) && <Button variant="primary">Creating</Button>}
                        </SpaceBetween>
                        }>
                            <Container
                            header={
            <Header variant="h2">
              Create A Directory
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Directory Path">
            <input 
          type="text" 
          required 
          value={directoryPathToAdd}
          onChange={(e) => setDirectoryPathToAdd(e.target.value)}
        />
            </FormField>
          
          </SpaceBetween>
        </Container>
      </Form>
    </form>
    </div>


    <div>
    <br></br>   
    <form onSubmit={handleSubmitFile}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link">
              Cancel
            </Button>
            {(isPendingAdd <= 0) && <Button variant="primary">Submit</Button>}
            {(isPendingAdd > 0) && <Button variant="primary">Creating</Button>}
          </SpaceBetween>
        }
      >
        <Container
          header={
            <Header variant="h2">
              Create A file
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Choose a file">
            <input 
          type="file" 
          required 
          onChange={(e) => {
            setFileToAdd(e.target.files[0])}}
        />
            </FormField>
          
          </SpaceBetween>
        </Container>
      </Form>
    </form>
    </div>


        </div>
    );
};

export default S3Content;

