import React, { Component, useState } from "react";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Alert from "@cloudscape-design/components/alert";
import ReadS3Bucket from "./ReadS3Bucket";
import Welcome from "./Welcome";


class SignedInContent extends Component {
    constructor(props){
        super(props);
        this.state = {
            userName: 'Red',
        }
        this.getUserName = this.getUserName.bind(this);
    }

  // Sign out will redirect the user to the sign in page
  signOutHandler = () => {
    window.location.href = "/";
  };

  // TODO: Get user name from the Idp
  getUserName = async() => {
    this.setState({
        userName: 'Red'
    })
  }

  render() {
    return (
      <ContentLayout
        header={
          <SpaceBetween size="m">
            <Header
              variant="h1"
              description="Ziti playground color system is equipped with security measures, providing both authentication and authorization mechanisms."
              actions={
                <Button variant="primary" onClick={this.signOutHandler}>
                  Sign Out
                </Button>
              }
            >
              <Welcome name={this.state.userName} />
            </Header>

            <Alert>Error message will display here.</Alert>
          </SpaceBetween>
        }
      >
        <Container
          header={
            <Header variant="h2" description="Color documents at S3 bucket.">
              Color Documents
            </Header>
          }
        >
          <SpaceBetween>
            <ReadS3Bucket />
          </SpaceBetween>
        </Container>
      </ContentLayout>
    );
  }
}

export default SignedInContent;
