import { useState, useEffect } from 'react';
import urlPrefix from "./urlPrefixGetter"

const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  url = urlPrefix() + url
  console.log("Print the url ", url)

  useEffect(() => {
    const abortCont = new AbortController();

    setTimeout(() => {
      fetch(url, { signal: abortCont.signal })
      .then(res => {
        console.log("response ", res)

        if (!res.ok) {
          console.log("response ", res)
          throw Error('could not fetch the data for that resource');
        } 
        console.log("response ", res)
        return res.json();
      })
      .then(data => {
        console.log("json response ", data)
        setIsPending(false);
        setData(data);
        setError(null);
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          console.log('fetch aborted', err)
        } else {
          setIsPending(false);
          console.log('Error', err)
          setError(err.message);
        }
      })
    }, 1000);

    // abort the fetch
    return () => abortCont.abort();
  }, [url])

  return { data, isPending, error };
}
 
export default useFetch;