import React from "react";
import Hooli from "../../images/HooliTeam.png";

function HooliTeamLogo() {
  return (
    <div>
      <img src={Hooli} alt="Hooli" style={{ width: 600 }} />
    </div>
  );
}

export default HooliTeamLogo;
