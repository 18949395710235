import * as React from "react";
import { AppLayout, SideNavigation } from "@cloudscape-design/components";
import MainContent from "./components/MainContent";
import BreadcrumbGroup from "./components/BreadcrumbGroup";
import LeftSideNavigation from "./components/LeftSideNavigation";
import HooliTeamLogo from "./components/contents/HooliTeamLogo";
import Box from "@cloudscape-design/components/box";


export default () => {
  return (
    <AppLayout
      breadcrumbs={<BreadcrumbGroup />}
      navigation={<LeftSideNavigation />}
      content={
        <div>
          <MainContent />
          {/* <Box float="bottom">
            <HooliTeamLogo />
          </Box> */}
        </div>
      }
      tools={
        <SideNavigation
          header={{
            text: "Ziti Team",
            href: "/",
          }}
          items={[
            {
              text: "Ziti Team Slack",
              type: "link",
              href: "https://amzn-aws.slack.com/archives/C05DAV1NKS8",
              external: true,
            },
          ]}
        />
      }
    />
  );
};
