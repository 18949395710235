import React, { Component } from "react";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Alert from "@cloudscape-design/components/alert";
import Welcome from "./Welcome";
import SignInModal from "./SignInModal";

class HomeContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  // User sign in will redirect user to the IDP sign in page
  openModal = () => {
    // The call back should be handled by the Idp OAuth flow
    // // call back url = domain + /home
    // window.open("https://d-90679d136b.awsapps.com/start", "_blank");
    this.setState(
      {
        modalVisible: true,
      },
      console.log("modal pop up", this.state.modalVisible)
    );
  };

  closeModal = () => {
    this.setState(
      {
        modalVisible: false,
      },
      console.log("modal pop up", this.state.modalVisible)
    );
  };

  render() {
    return (
      <ContentLayout
        header={
          <SpaceBetween size="m">
            <Header
              variant="h1"
              description="Ziti playground color system is equipped with security measures, providing both authentication and authorization mechanisms."
              actions={
                <Button variant="primary" onClick={this.openModal}>
                  Sign In
                </Button>
              }
            >
              <Welcome name="Guest" />
            </Header>

            <Alert>Error message will display here.</Alert>
          </SpaceBetween>
        }
      >
        <SignInModal
          modalVisible={this.state.modalVisible}
          closeModal={this.closeModal}
        />
        <Container
          header={
            <Header
              variant="h2"
              description="Please sign in to read Color Documents at S3 bucket."
            >
              Color Documents
            </Header>
          }
        ></Container>
      </ContentLayout>
    );
  }
}

export default HomeContent;
