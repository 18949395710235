import * as React from "react";
import SideNavigation from "@cloudscape-design/components/side-navigation";

export default () => {
  const [activeHref, setActiveHref] = React.useState(""
  );
  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: "#/", text: "Zero Trust References" }}
      onFollow={event => {
        if (!event.detail.external) {
          event.preventDefault();
          setActiveHref(event.detail.href);
        }
      }}
      items={[
        { type: "link", text: "Zero Trust Primer", href: "https://quip-amazon.com/hUscAjqI7T4V/Ziti-team-onboarding-references", external: true},
        {
          type: "section",
          text: "AWS Verified Access (AVA)",
          items: [
            {
              type: "link",
              text: "AWS Verified Access Overview",
              href: "https://aws.amazon.com/verified-access/",
              external: true
            },
            {
              type: "link",
              text: "AWS Verified Access Workshop",
              href: "https://catalog.us-east-1.prod.workshops.aws/workshops/dc70f5b4-a400-4c33-9a8e-c1b2baebbea4/en-US",
              external: true
            },
          ]
        },
        {
          type: "section",
          text: "Amazon Verified Permissions (AVP)",
          items: [
            {
              type: "link",
              text: "Amazon Verified Permissions Overview",
              href: "https://aws.amazon.com/verified-permissions/",
              external: true
            },
            {
              type: "link",
              text: "Amazon Verified Permissions workshop",
              href: "https://catalog.workshops.aws/verified-permissions-in-action/en-US",
              external: true
            },
          ]
        },
        {
            type: "section",
            text: "Amazon VPC Lattice",
            items: [
              {
                type: "link",
                text: "Amazon VPC Lattice Overview",
                href: "https://aws.amazon.com/vpc/lattice/",
                external: true
              },
            ]
          },
          {
            type: "section",
            text: "AWS IAM Identity Center (IdC)",
            items: [
              {
                type: "link",
                text: "AWS IAM Identity Center Overview",
                href: "https://aws.amazon.com/iam/identity-center/",
                external: true
              },
            ]
          }
      ]}
    />
  );
}