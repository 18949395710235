const urlPrefix = () => {
  const parsedUrl = new URL(window.location.href);
  const domain = parsedUrl.hostname.split('.')[0]
    let urlPrefix = 'https://prod.backend.ziti-demos.identity.aws.dev'
    if (domain.includes('beta')) {
      urlPrefix = 'https://beta.backend.ziti-demos.identity.aws.dev'
    } else if (domain.includes('localhost')) {
      urlPrefix = 'http://localhost:5000'
    }
    return urlPrefix
}

export default urlPrefix;