import * as React from "react";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";

export default () => {
  return (
    <BreadcrumbGroup
      items={[
        { text: "Ziti-Team", href: "#" },
        { text: "Ziti-Playground", href: "#playground" },
        {
          text: "Color-System",
          href: "#playground/color-system",
        },
      ]}
      ariaLabel="ZitiPlayGround"
    />
  );
};
