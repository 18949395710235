import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeContent from "./contents/HomeContent";
import SignedInContent from "./contents/SignedInContent";
import S3Content from "./contents/S3Content";
import'../s3Content.css'

class MainContent extends React.Component {

  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" Component={HomeContent}></Route>
          <Route path="/home" Component={SignedInContent}></Route>
          <Route path="/ListContents" Component={S3Content}></Route>
        </Routes>
      </Router>
    );
  }
}

export default MainContent;
